import React from "react";
import { Header } from "../../components/Header";
import { Sidebar } from "../../components/Sidebar";
import { useIsMobileSize } from "../../hooks/is-mobile-size";
import { LayoutProps } from "./interfaces";
import { useStyles } from "./styles";

export const Layout: React.FC<LayoutProps> = ({ menuCollapsed, children }) => {
  const classes = useStyles();

  const { isTabletSize, isMobileSize } = useIsMobileSize();

  return (
    <div className={classes.layout}>
      {!isTabletSize && !isMobileSize && <Header collapsed={menuCollapsed} />}
      <article className={classes.container}>
        <Sidebar collapsed={menuCollapsed} />

        {children}
      </article>
    </div>
  );
};
