import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { LocalizedText } from "../LocalizedText";
import { CloseButton } from "../Panel";
import React from "react";
import { LocalizedButton } from "../Button";
import { UserContext } from "../../contexts/UserContext";
import { makeStyles } from "@material-ui/core/styles";

export interface OutOfDialogProps {
  show: boolean;
  DialogType: "OUT_OF_TRIAL" | "MORE_INFO";
  onClose: () => void;
  salesforceId: string;
}

export const OutOfTrialDialogView: React.FC<OutOfDialogProps> = ({
  show,
  DialogType,
  onClose,
  salesforceId,
}) => {
  const { ctxUser } = React.useContext(UserContext);
  const classes = useStyles();

  const handleUpgrade = () => {
    Intercom("trackEvent", "livecamera-watchdemo-requested_v2");

    const url =
      "https://ram2ams.azurewebsites.net/api/RAMMarketing?" +
      "code=FZDqro1VuczY2-HPWgRzRoqwDUCfh0rMvKg9jRfrCjhYAzFu01v-tw==" +
      "&sf_campaign=Camera%20Icon" +
      "&utm_medium=Watch%20Demo&utm_campaign=Upgrade&utm_source=From%20RAM%20Tracking" +
      "&destinationURL=https%3A%2F%2Fwww2.ramtracking.com%2Fram-live-thank-you" +
      "&account_id=" +
      salesforceId +
      "&user_id=" +
      ctxUser?.salesforceContactId;

    window.open(url, "_blank", "noopener");
  };

  const upgradeButton = (
    <LocalizedButton
      fullWidth
      variant="contained"
      color="secondary"
      onClick={handleUpgrade}
    >
      Keep My Video Access!
    </LocalizedButton>
  );

  const outOfTrial = DialogType === "OUT_OF_TRIAL";
  const title =
    "Unlock Instant Access To Your Dash Cam Footage With RAM Live Vision";
  const heading = <i>Preview, Prevent, Protect</i>;
  const closingText = outOfTrial
    ? `Without upgrading, instant video access ends now, and you’ll be reverted to the Alert package.`
    : `Without upgrading when your trial ends, instant video access will expire, and you'll be reverted to the Alert package.`;

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      aria-describedby="alert-dialog-description"
      keepMounted
      open={show}
      onClose={onClose}
      style={{ padding: "100px" }}
    >
      <DialogTitle id="simple-dialog-title" disableTypography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" className={classes.centerText}>
            <LocalizedText text={title} />
          </Typography>
          <CloseButton onClick={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <Typography variant="h6" paragraph className={classes.centerText}>
            {heading}
          </Typography>
          <FeatureList outOfTrial={outOfTrial} />
          <Typography variant="body1" paragraph className={classes.centerText}>
            {closingText}
          </Typography>
        </Box>
      </DialogContent>
      <DialogContent dividers>{upgradeButton}</DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    alignItems: "stretch",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: "1.2rem",
  },
  centerText: {
    textAlign: "center",
  },
  featuresContainer: {
    marginTop: theme.spacing(2),
  },
}));

export const FeatureList: React.FC<{ outOfTrial: boolean }> = ({
  outOfTrial,
}) => {
  const classes = useStyles();

  const subHeading = outOfTrial ? (
    <Typography variant="body1" style={{ fontWeight: 700 }}>
      Free trial ending: Keep what you've unlocked. With RAM Live Vision, you
      get:
    </Typography>
  ) : (
    <Typography variant="body1" style={{ fontWeight: 700 }}>
      Free trial in progress: Experience premium features now.
    </Typography>
  );

  const features = [
    { icon: "📹", text: "Instant video and photo proof when incidents happen" },
    { icon: "💪", text: "Protection against false claims" },
    { icon: "👀", text: "Greater insight into driver behaviour" },
    { icon: "💻", text: "Complete access to web and app features" },
  ];

  return (
    <Box>
      {subHeading}

      <Box className={classes.featuresContainer}>
        {features.map((item, index) => (
          <Box key={index} className={classes.listItem}>
            <Typography className={classes.icon}>{item.icon}</Typography>
            <Typography variant="body1">{item.text}</Typography>
          </Box>
        ))}
      </Box>

      {!outOfTrial && (
        <Box>
          <Typography variant="body1" paragraph className={classes.centerText}>
            Enjoy these premium features during your trial period
          </Typography>
        </Box>
      )}
    </Box>
  );
};
