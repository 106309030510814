import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CarIcon from "@material-ui/icons/DriveEta";
import AccountsIcon from "@material-ui/icons/EmojiTransportation";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import AssessmentIcon from "@material-ui/icons/Assessment";
import WarningIcon from "@material-ui/icons/Warning";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { Spin as Hamburger } from "hamburger-react";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ssoAdminRole } from "../../constants";
import { ImpersonationContext } from "../../contexts/ImpersonationContext";
import { UserContext } from "../../contexts/UserContext";
import { useTranslation } from "../../hooks/i18n";
import { useIsMobileSize } from "../../hooks/is-mobile-size";
import { isHome, routeIsActive } from "../../services/navigation";
import { Avatar } from "../Avatar";
import { Header } from "../Header";
import "./hamburger.css";
import {
  SidebarMenuItemProps,
  SidebarProps,
  SidebarSubMenuProps,
} from "./interfaces";
import { useStyles } from "./styles";

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  icon: Icon,
  label,
  link,
  collapsed,
  selected,
  onClick,
}) => {
  const classes = useStyles({ collapsed });
  const { t } = useTranslation();
  const { isMobileSize, isTabletSize } = useIsMobileSize();
  const showItemLabels = isMobileSize || isTabletSize || !collapsed;

  const item = (
    <ListItem
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- TS seems to have a problem with link being optional here, but as we only use Link if link is defined, not sure what the issue is
      // @ts-ignore
      component={link ? Link : undefined}
      to={link ? link : undefined}
      selected={selected || (link ? routeIsActive(link) : false)}
      button
      classes={{
        root: classes.listItem,
        selected: classes.listItemSelected,
      }}
      onClick={onClick}
    >
      <ListItemIcon className={classes.listIconContainer}>
        <Icon className={classes.listIcon} />
      </ListItemIcon>

      {showItemLabels && (
        <ListItemText
          primary={t(label)}
          classes={{ primary: classes.listItemLabel }}
        />
      )}
    </ListItem>
  );

  return collapsed ? (
    <Tooltip placement="right" title={t(label) as string}>
      {item}
    </Tooltip>
  ) : (
    item
  );
};

const CustomerMenu: React.FC<SidebarSubMenuProps> = ({
  collapsed,
  impersonating,
}) => {
  return (
    <>
      <SidebarMenuItem
        icon={CarIcon}
        label="vehicles"
        link="/vehicles"
        collapsed={collapsed}
        selected={isHome()}
      />

      <SidebarMenuItem
        icon={WarningIcon}
        label="events"
        link="/events"
        collapsed={collapsed}
      />

      <SidebarMenuItem
        icon={CalendarTodayIcon}
        label="calendar"
        link="/calendar"
        collapsed={collapsed}
      />

      <SidebarMenuItem
        icon={VideoLibraryIcon}
        label="video"
        link="/video"
        collapsed={collapsed}
      />
    </>
  );
};

const AdminMenu: React.FC<SidebarSubMenuProps> = ({ collapsed }) => {
  return (
    <>
      <SidebarMenuItem
        icon={AccountsIcon}
        label="accounts"
        link="/accounts"
        collapsed={collapsed}
        selected={isHome()}
      />
      <SidebarMenuItem
        icon={AssessmentIcon}
        label="reports"
        link="/reports"
        collapsed={collapsed}
      />
    </>
  );
};

export const Sidebar: React.FC<SidebarProps> = ({ collapsed }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const classes = useStyles({ collapsed, menuOpen });
  const { t } = useTranslation();
  const { listen, push } = useHistory();

  const { ctxUser } = React.useContext(UserContext);

  React.useEffect(() => {
    const unlisten = listen((_) => {
      setMenuOpen(false);
    });

    return unlisten;
  }, [listen]);

  const { isMobileSize, isTabletSize } = useIsMobileSize();
  const showAvatarInHeader = isMobileSize || isTabletSize || !collapsed;

  const { iUser, setIUser } = React.useContext(ImpersonationContext);

  return (
    <header data-testid="sidebar" className={classes.container}>
      <div className={classes.header}>
        <Header collapsed={collapsed} />
        <div className={classes.mobileMenuButton}>
          <Hamburger
            toggled={menuOpen}
            toggle={setMenuOpen}
            label={t("toggle_menu")}
            rounded
          />
        </div>
      </div>

      <nav
        className={
          menuOpen
            ? classes.list
            : [classes.list, classes.hideMobileMenu].join(" ")
        }
      >
        <List>
          {ctxUser?.roles?.includes(ssoAdminRole) && !iUser ? (
            <AdminMenu collapsed={collapsed} menuOpen={menuOpen} />
          ) : (
            <CustomerMenu
              collapsed={collapsed}
              menuOpen={menuOpen}
              impersonating={Boolean(iUser)}
            />
          )}

          {ctxUser && !showAvatarInHeader && (
            <ListItem
              classes={{
                root: [classes.listItem, classes.avatarListItem].join(" "),
              }}
            >
              <Link to="/profile">
                <Avatar
                  firstName={ctxUser.firstName}
                  lastName={ctxUser.lastName}
                  size="xs"
                  variant="light"
                  impersonating={Boolean(iUser)}
                />
              </Link>
            </ListItem>
          )}
          {iUser && (
            <>
              <Divider className={classes.divider} variant="middle" />
              <SidebarMenuItem
                icon={AssignmentIndIcon}
                label="quit_impersonation"
                collapsed={collapsed}
                onClick={() => {
                  setIUser(undefined);
                  push("/");
                }}
              />
              {!collapsed && (
                <Typography
                  color="secondary"
                  className={classes.impersonatingText}
                >
                  You are impersonating: {iUser.name}
                </Typography>
              )}
            </>
          )}
        </List>
      </nav>
    </header>
  );
};
