import * as crypto from "crypto";
import { User } from "../hooks/auth";
import { Account } from "../interfaces";

interface CustomIntercomSettings extends Intercom_.IntercomSettings {
  SalesforceAccountId?: string;
  RAM_Live_user_type?: string;
  RAM_Live_signed_up_date?: string;
  RAM_Live_country?: string;
}

interface UpdateCustomCompanyIntercomSettings
  extends Intercom_.IntercomCompany {
  Account_Has_Queclinks_Camera: boolean;
  Account_Has_MFL_Camera: boolean;
}

const generateHash = (user: User) => {
  const hmac = crypto.createHmac(
    "sha256",
    process.env.REACT_APP_INTERCOM_SECRET_KEY || ""
  );

  const hashValue = user.salesforceContactId || user.id;
  hmac.update(hashValue);
  return hmac.digest("hex");
};

export const initIntercomWithUser = async (
  user: User,
  account: Account
): Promise<void> => {
  const settings: CustomIntercomSettings = {
    // Account Config
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    name: user.firstName + " " + user.lastName,
    email: user.email,
    user_hash: generateHash(user),
    user_id: user.salesforceContactId,
    company: {
      name: account.name,
      company_id: account.salesforceId,
    },
    // CSS
    custom_launcher_selector: "#intercom_launcher",
    hide_default_launcher: true,
  };

  if (user.salesforceContactId) {
    Intercom("boot", settings);
  }
};

export const shutdown = (): void => {
  Intercom("shutdown");
};

export const update = async (
  account: Account,
  props: IntercomCompanyUpdate
): Promise<void> => {
  const updateCompanyDetails: UpdateCustomCompanyIntercomSettings = {
    name: account.name,
    company_id: account.salesforceId,
    Account_Has_Queclinks_Camera: props.hasQueclinkCamera,
    Account_Has_MFL_Camera: props.hasMflCamera,
  };

  if (typeof Intercom !== "undefined") {
    Intercom("update", {
      company: updateCompanyDetails,
    });
  }
};

export const openReferAndEarn = (): void => {
  Intercom("startSurvey", 34105132);
};

export interface IntercomCompanyUpdate {
  hasQueclinkCamera: boolean;
  hasMflCamera: boolean;
}
