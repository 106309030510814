import { Button, ButtonBase, Divider, Grid } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ReactComponent as AppsRoundedIcon } from "./assets/apps.svg";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import StarIcon from "@material-ui/icons/Star";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import RedeemIcon from "@material-ui/icons/Redeem";
import HelpIcon from "@material-ui/icons/Help";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { ImpersonationContext } from "../../contexts/ImpersonationContext";
import { UserContext } from "../../contexts/UserContext";
import { Avatar } from "../Avatar";
import { Logo } from "../Logo";
import RAMAssistLogo from "./assets/ram-assist.png";
import RAMMarketLogo from "./assets/ram-marketplace.png";
import RAMTrackingLogo from "./assets/ram-tracking.png";
import { HeaderViewProps } from "./interfaces";
import { usePackageBannerSyles, useStyles } from "./styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import clsx from "clsx";
import { openReferAndEarn, shutdown } from "../../services/intercom";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useAccountsApi } from "../../hooks/use-accounts";
import { Account } from "../../interfaces";
import { OutOfTrialDialogView } from "../OutOfTrialDialog/views";
import AlertsPackageBannerImage from "./assets/Live-Alerts.png";
import VisionPackageBannerImage from "./assets/Live-Vision.png";
import { calculateRemainingDays } from "./utils";

export const HeaderView: React.FC<HeaderViewProps> = ({
  collapsed,
  unreadIntercomMsgs,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { getAccount } = useAccountsApi();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(
    undefined
  );
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const auth: IAuthContext = useContext(AuthContext);

  const handleSignOut = () => {
    auth.logOut();
    shutdown();
    window.location.replace(
      process.env.REACT_APP_SSO_API_URL +
        "/logout?redirect=" +
        process.env.REACT_APP_SSO_REDIRECT_URI
    );
  };

  const [openMenu, setOpenMenu] = React.useState<string>();

  const { ctxUser } = React.useContext(UserContext);

  React.useEffect(() => {
    async function fetchData() {
      const data = await getAccount(ctxUser?.accountId || "");
      setAccount(data);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, [ctxUser?.accountId]);

  const { iUser } = React.useContext(ImpersonationContext);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(undefined);
    setOpenMenu(undefined);
    e.stopPropagation(); //Stops handleClick being triggered again
  };

  const handleReferAndEarn = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    handleClose(e);
    openReferAndEarn();
  };

  let menuContent;
  switch (openMenu) {
    case "Products":
      menuContent = (
        <div>
          <h3>Access our products</h3>
          <a
            className={classes.noStyle}
            href="https://www.ramwebtracking.com"
            target="_blank"
            rel="noreferrer"
          >
            <MenuItem className={classes.ramExternalItem} onClick={handleClose}>
              <div
                style={{
                  display: "flex",
                  alignItems: "stetch",
                }}
              >
                <div className={classes.logoContainer}>
                  <img
                    className={classes.logo}
                    src={RAMTrackingLogo}
                    alt="RAM Tracking Logo"
                  />
                </div>
                <div className={classes.spanContainer}>
                  <span className={classes.ramExternalSpan}>
                    Track drivers in real time, route replay, reports
                  </span>
                </div>
              </div>
            </MenuItem>
          </a>
          <a
            className={classes.noStyle}
            href="https://www.ramassist.com"
            target="_blank"
            rel="noreferrer"
          >
            <MenuItem className={classes.ramExternalItem} onClick={handleClose}>
              <div
                style={{
                  display: "flex",
                  alignItems: "stetch",
                }}
              >
                <div className={classes.logoContainer}>
                  <img
                    className={classes.logo}
                    src={RAMAssistLogo}
                    alt="RAM Assist Logo"
                  />
                </div>
                <div className={classes.spanContainer}>
                  <span className={classes.ramExternalSpan}>
                    Complete driver activity and vehicle management solution.
                  </span>
                </div>
              </div>
            </MenuItem>
          </a>
          <a
            className={classes.noStyle}
            href="https://www.ramtracking.com/marketplace/"
            target="_blank"
            rel="noreferrer"
          >
            <MenuItem className={classes.ramExternalItem} onClick={handleClose}>
              <div
                style={{
                  display: "flex",
                  alignItems: "stetch",
                }}
              >
                <div className={classes.logoContainer}>
                  <img
                    className={classes.logo}
                    src={RAMMarketLogo}
                    alt="RAM Marketplace Logo"
                  />
                </div>
                <div className={classes.spanContainer}>
                  <span className={classes.ramExternalSpan}>
                    Exclusive discounts and offers from some of the biggest
                    companies in the UK
                  </span>
                </div>
              </div>
            </MenuItem>
          </a>
        </div>
      );
      break;
    case "Account":
      menuContent = (
        <div>
          <Grid container spacing={1} style={{ padding: "8px" }}>
            <Grid item xs={3}>
              <Link
                onClick={handleClose}
                style={{ textDecoration: "none" }}
                to={iUser ? "" : "/profile"}
              >
                <Avatar
                  firstName={ctxUser?.firstName || "R"}
                  lastName={ctxUser?.lastName || "L"}
                  size="s"
                  impersonating={Boolean(iUser)}
                />
              </Link>
            </Grid>
            <Grid item xs={9}>
              {ctxUser?.firstName} {ctxUser?.lastName}
              <br />
              {ctxUser?.email || "Loading..."}
            </Grid>
          </Grid>
          <Divider key="divider" />
          {!iUser ? (
            <div>
              <MenuItem
                disableGutters
                key="settings"
                onClick={(e) => {
                  history.push("/settings");
                  handleClose(e);
                }}
              >
                <SettingsIcon fontSize="small" style={{ marginRight: "6px" }} />{" "}
                General Settings
              </MenuItem>
              <Divider key="divider-settings" />
              <a
                className={classes.noStyle}
                target="_blank"
                rel="noreferrer"
                href="https://news.ramtracking.com/"
              >
                <MenuItem disableGutters key="whatsNew">
                  <StarIcon fontSize="small" style={{ marginRight: "6px" }} />{" "}
                  What's New
                </MenuItem>
              </a>
              <a
                className={classes.noStyle}
                target="_blank"
                rel="noreferrer"
                href="https://go.ramtracking.com/l/28952/2022-07-06/9mfpjk"
              >
                <MenuItem disableGutters key="knowledgeBase">
                  <MenuBookIcon
                    fontSize="small"
                    style={{ marginRight: "6px" }}
                  />{" "}
                  Knowledge Base
                </MenuItem>
              </a>
              <MenuItem
                disableGutters
                key="referAndEarn"
                onClick={handleReferAndEarn}
              >
                <RedeemIcon fontSize="small" style={{ marginRight: "6px" }} />{" "}
                Refer And Earn
              </MenuItem>
            </div>
          ) : (
            ""
          )}
          <Divider key="divider-account" />
          <MenuItem disableGutters key="sign_out" onClick={handleSignOut}>
            <ExitToAppIcon fontSize="small" style={{ marginRight: "6px" }} />{" "}
            Logout
          </MenuItem>
        </div>
      );
      break;
    default:
      menuContent = null;
      break;
  }

  return (
    <div className={classes.container} data-testid="header">
      <div
        style={{
          width: "16rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {account && <TrialRemainder account={account} />}
      <div className={classes.navButtons}>
        <ButtonBase
          data-testid="nav-intercom"
          id="intercom_launcher"
          className={clsx({
            [classes.navItem]: true,
          })}
        >
          <div style={{ width: "fit-content", position: "relative" }}>
            <HelpIcon fontSize="large" />
            <IntercomMessageCounter amount={unreadIntercomMsgs} />
          </div>
        </ButtonBase>
        <ButtonBase
          onClick={(e) => {
            setOpenMenu("Products");
            handleClick(e);
          }}
          className={clsx({
            [classes.navItem]: true,
            [classes.active]: openMenu === "Products",
          })}
        >
          <SvgIcon fontSize="large">
            <AppsRoundedIcon />
          </SvgIcon>
        </ButtonBase>
        <ButtonBase
          data-testid="nav-account"
          onClick={(e) => {
            setOpenMenu("Account");
            handleClick(e);
          }}
          className={clsx({
            [classes.navItem]: true,
            [classes.active]: openMenu === "Account",
          })}
        >
          <div style={{ width: "fit-content", position: "relative" }}>
            <AccountCircleIcon fontSize="large" />
          </div>
        </ButtonBase>
        <Menu
          elevation={0}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ paper: classes.menu }}
          MenuListProps={{ disablePadding: true }}
        >
          {menuContent}
        </Menu>
      </div>
    </div>
  );
};

export const IntercomMessageCounter: React.FC<{ amount: number }> = ({
  amount,
}) => {
  const classes = useStyles();

  return amount ? (
    <span className={classes.intercom_message_counter}>
      {amount <= 99 ? amount : "99+"}
    </span>
  ) : null;
};

export const TrialRemainder: React.FC<{ account: Account }> = ({ account }) => {
  const classes = usePackageBannerSyles();
  const [showOutOfTrialModal, setShowOutOfTrialModal] = React.useState(false);

  const daysRemaining = calculateRemainingDays(account.trialStartDate);

  if (daysRemaining <= 0 || account.packageType === "PLUS") {
    return <PackageBanner account={account} />;
  }

  const text = `${daysRemaining} days remaining in trial`;

  return (
    <div className={classes.trialReminder}>
      <ButtonBase
        onClick={(e) => {
          setShowOutOfTrialModal(true);
        }}
      >
        <span>{text}</span>
      </ButtonBase>
      <OutOfTrialDialogView
        show={showOutOfTrialModal}
        DialogType={daysRemaining <= 0 ? "OUT_OF_TRIAL" : "MORE_INFO"}
        onClose={() => setShowOutOfTrialModal(false)}
        salesforceId={account.salesforceId || ""}
      />
    </div>
  );
};

const PackageBanner: React.FC<{ account: Account }> = ({ account }) => {
  const classes = usePackageBannerSyles();
  const [showOutOfTrialModal, setShowOutOfTrialModal] = React.useState(false);

  const isCorePackage = account.packageType === "CORE";

  const img = isCorePackage
    ? AlertsPackageBannerImage
    : VisionPackageBannerImage;

  return (
    <div className={classes.packageBanner}>
      <div style={{ position: "relative", textAlign: "center" }}>
        <div style={{ position: "absolute", top: "0", width: "100%" }}>
          <span style={{ fontSize: "11px" }}>Current Package</span>
        </div>
        <img
          src={img}
          alt="Current Package"
          style={{ width: "100px", marginTop: "20px" }}
        />
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        {isCorePackage && (
          <Button
            className={classes.upgradeButton}
            onClick={() => setShowOutOfTrialModal(true)}
          >
            Upgrade Options
          </Button>
        )}
      </div>
      <OutOfTrialDialogView
        show={showOutOfTrialModal}
        DialogType="OUT_OF_TRIAL"
        onClose={() => setShowOutOfTrialModal(false)}
        salesforceId={account.salesforceId || ""}
      />
    </div>
  );
};
