import { DateTime } from "luxon";

import { Filters } from "./interfaces";

export const getInitialFilters = (): Filters => ({
  type: [],
  severity: [],
  vehicle: [],
  videoOnly: false,
  date: [
    DateTime.now().startOf("day").toISO(),
    DateTime.now().endOf("day").toISO(),
  ],
});
